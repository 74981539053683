/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: sans-serif;*/
/*}*/

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 16px;
  color: #191932;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

