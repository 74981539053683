.App {
  position: relative;
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 50px;
}

.App-header {
  background-color: #222;
  height: 55px;
  padding: 12px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/****************************/

.order-info p {
  margin-bottom: 5px;
}

.couriersMap{
  height: calc(100vh - 60px);
  width: calc(100vw - 500px);
}

.selectFilters {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 670px);
}

.filterSelectDate {
  width: 150px !important;
  height: 40px;
  padding: 0 18px 2px;
  outline: 0;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  background: #fff;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.65);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.CourierLegend {
  text-align: left;
  margin: 20px 0 50px;
  height: 580px;
  overflow-y: scroll;
  padding-left: 30px;
}

.CourierLegendColor {
  width: 17px;
  height: 17px;
  display: inline-block;
  margin:0 11px;
}

.courier_map_date  .react-datepicker-wrapper {
  margin-top: 20px;
}

.courier_map_date .react-datepicker__tab-loop {
  margin-bottom: -21px;
}

/*.header {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  z-index: 20;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  width: 100%;*/
/*  height: 60px;*/
/*  background-color: #fff;*/
/*  box-shadow: 0 -8px 16px 0 rgba(0,0,0,0.5);*/
/*}*/

/****************************/

.wallets p, .client-card p, .reports p {
  margin: 0;
}


.wallets_wrapper, .files_wrapper {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 16px;
  color: #191932;
}

.wallets_wrapper input, .filters_block input, .assembly_date input, .reports input {
  height: 36px;
  padding: 10px 18px;
  outline: 0;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #eee;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #191932;
}

.assembly_date input {
  background: #3d425e;
  color: #ffffff;
}

.input_file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input_file + label {
  height: 28px;
  padding: 0 20px;
  border-radius: 66px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 30px;
  color: white;
  background-color: #ff1744;
  display: inline-block;
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: #999;
}
::-ms-input-placeholder {
  color: #999;
}
::placeholder {
  color: #999;
}

.flex_row_start_center {
  display: flex;
  align-items: center;
}

.flex_row_sb_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_row_sb {
  display: flex;
  justify-content: space-between;
}

.add_time input, .filters_block input, .assembly_date input, .reports input {
  width: 150px;
  padding: 0;
  text-align: center;
}

.add_time input:focus, .filters_block input:focus {
  background: #fff;
}

.assembly_date input:focus {
  color: #191932;
  background: #fff;
}

.datepicker {
  width: 90px;
  text-align: center;
  cursor: pointer;
}

.reports_date {
  margin-right: 10px;
}
.modalStyle .ant-modal-body{
  padding: 0px;
}
.modalPhoto .ant-modal-body{
  padding: 24px;
}

.modals .ant-modal-content{
  border-radius: 16px;
  padding: 40px;
  width: 655px;
  /* height: 246px; */
}
.modals .ant-modal-body{
  padding: 0;
}
.SelectCustom .ant-select-selection--single{
  width: 100%;
  height: 50px;
}
.SelectCustom .ant-select-selection{
  width: 100%;
  height: 50px;
}
.SelectCustom .ant-select-selection--single .ant-select-selection__rendered{
  display: flex;
  align-items: center;
}



/*.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  cursor: default;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  -ms-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}
.popup {
  background-color: #fff;
  border: 3px solid #fff;
  display: inline-block;
  left: 30%;
  opacity: 0;
  padding: 15px;
  position: fixed;
  text-align: justify;
  font: 14px Helvetica, Arial, Sans-Serif;
  top: 25%;
  visibility: hidden;
  z-index: 10;

  -webkit-transform: translate(-17%, -17%);
  -moz-transform: translate(-17%, -17%);
  -ms-transform: translate(-17%, -17%);
  -o-transform: translate(-17%, -17%);
  transform: translate(-17%, -17%);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;

  -webkit-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -moz-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -ms-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  -o-box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.4) inset;

  -webkit-transition: opacity .5s, top .5s;
  -moz-transition: opacity .5s, top .5s;
  -ms-transition: opacity .5s, top .5s;
  -o-transition: opacity .5s, top .5s;
  transition: opacity .5s, top .5s;
}
.overlay:target+.popup {
  top: 25%;
  opacity: 1;
  visibility: visible;
}
.close {
  background-color: rgba(0, 0, 0, 0.8);
  height: 30px;
  line-height: 30px;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: -15px;
  width: 30px;

  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
.close:before {
  color: rgba(255, 255, 255, 0.9);
  content: "X";
  font-size: 24px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.9);
}
.close:hover {
  background-color: rgba(64, 128, 128, 0.8);
}
.popup p, .popup div {
  padding: 0px 5px 0px 5px;
}*/
